import type { Head } from '@unhead/schema'
import consts from '@/consts'

import type {
  PageDocument,
  SuccessStoryDocument,
  BlogArticleDocument,
  ResourceDocument,
  ProductDocument,
  NewsArticleDocument,
  LandingPageDocument,
  EventDocument,
  SolutionsPageDocument,
} from '@/prismicio-types'

type PageDocumentType =
  | PageDocument<string>
  | ProductDocument<string>
  | LandingPageDocument<string>
  | SuccessStoryDocument<string>
  | BlogArticleDocument<string>
  | ResourceDocument<string>
  | NewsArticleDocument<string>
  | EventDocument<string>
  | SolutionsPageDocument<string>

export default (data: PageDocumentType | null): Head => {
  const globalSettings = useGlobalSettings()
  const switchLocalePath = useSwitchLocalePath()
  const { locale, localeProperties } = useI18n()

  const isProduction = useIsProduction()
  const isLocalhost = useRequestURL().hostname === 'localhost'
  const isStaging = !isProduction && !isLocalhost

  const shouldHaveNoIndexMarker = isStaging

  const ogImgW = 1200
  const ogImgH = 630

  let robots: string = ''
  if (data?.data) {
    robots = data.data.seo_index ? 'index' : 'noindex'
    robots += data.data.seo_follow ? ', follow' : ', nofollow'
  }

  if (shouldHaveNoIndexMarker) {
    robots = 'noindex, nofollow, noarchive, nosnippet'
  }

  if (data?.data) {
    const isOgTypeArticle =
      data.type === 'blog_article' ||
      data.type === 'event' ||
      data.type === 'news_article' ||
      data.type === 'resource' ||
      data.type === 'success_story'

    const metaLinks = [
      {
        rel: 'canonical',
        href: getAllowedCanonicalUrl(),
      },
      {
        rel: 'alternate',
        hreflang: localeProperties.value.language,
        href: getCleanedCanonicalUrl(consts.baseUrl + switchLocalePath(locale.value)),
        id: 'i18n-alt-' + localeProperties.value.language,
      },
    ]
    if (data.alternate_languages) {
      for (let i = 0; i < data.alternate_languages.length; i++) {
        const locale = data.alternate_languages[i]
        metaLinks.push({
          rel: 'alternate',
          hreflang: locale.lang.slice(0, 2),
          href: getCleanedCanonicalUrl(consts.baseUrl + switchLocalePath(locale.lang)),
          id: 'i18n-alt-' + locale.lang.slice(0, 2),
        })
      }
    }

    const paginationTitlePostfix =
      getPaginationNumber() !== null ? ' - ' + getPaginationNumber() : ''

    const ogAndTwitterImg = data.data.meta_image?.url
      ? data.data.meta_image.url
      : 'featured_image' in data.data && data.data.featured_image?.url
        ? data.data.featured_image?.url
        : globalSettings.value?.data.meta_image_default.url
          ? globalSettings.value?.data.meta_image_default.url
          : consts.defaultOpenGraphImage
    const ogAndTwitterImgAlt = data.data.meta_image?.alt
      ? data.data.meta_image.alt
      : 'featured_image' in data.data && data.data.featured_image?.url
        ? data.data.featured_image?.alt
        : globalSettings.value?.data.meta_image_default.alt
          ? globalSettings.value?.data.meta_image_default.alt
          : consts.companyName

    return {
      title:
        (data.data.meta_title ? data.data.meta_title : data.data.title) + paginationTitlePostfix,
      link: metaLinks,
      meta: [
        {
          hid: 'robots',
          name: 'robots',
          content: robots,
        },
        { name: 'description', content: data.data.meta_description ?? data.data.meta_description },
        {
          name: 'og:site_name',
          content: consts.companyName,
        },
        {
          name: 'og:type',
          content: isOgTypeArticle ? 'article' : 'website',
        },
        {
          name: 'og:image',
          content:
            getCleanUrl(ogAndTwitterImg) + getImgixQueryParams({ w: ogImgW, h: ogImgH, q: 80 }),
        },
        {
          name: 'og:image:height',
          content: ogImgH,
        },
        {
          name: 'og:image:width',
          content: ogImgW,
        },
        {
          name: 'og:image:alt',
          content: ogAndTwitterImgAlt,
        },
        {
          name: 'og:title',
          content:
            (data.data.og_title ? data.data.og_title : data.data.title) + paginationTitlePostfix,
        },
        {
          name: 'og:description',
          content: data.data.og_description ? data.data.og_description : data.data.meta_description,
        },
        {
          name: 'og:url',
          content: getAllowedCanonicalUrl(),
        },
        {
          name: 'twitter:image',
          content: getCleanUrl(ogAndTwitterImg) + getImgixQueryParams({ w: 1200, h: 630, q: 80 }),
        },
        {
          name: 'twitter:image:alt',
          content: ogAndTwitterImgAlt,
        },
        {
          name: 'twitter:title',
          content: data.data.twitter_title
            ? data.data.twitter_title
            : data.data.og_title
              ? data.data.og_title
              : data.data.title,
        },
        {
          name: 'twitter:description',
          content: data.data.twitter_description
            ? data.data.twitter_description
            : data.data.meta_description,
        },
        {
          name: 'twitter:site',
          content: '@beqom',
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          name: 'twitter:site:id',
          content: '@beqom',
        },
      ],
    }
  }

  return {
    title: consts.companyName,
    meta: [
      {
        hid: 'robots',
        name: 'robots',
        content: robots,
      },
    ],
  }
}
