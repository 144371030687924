import consts from '@/consts'

export default (url: string | URL) => {
  const parsedURL = new URL(url)

  const queryParams = new URLSearchParams(parsedURL.search)

  const filteredQueryParams = new URLSearchParams()

  queryParams.forEach((value, key) => {
    const allowedParam = consts.allowedCanonicalQueryParams.find(
      (paramObj) => paramObj.param === key && paramObj.path.includes(parsedURL.pathname),
    )

    if (allowedParam) {
      filteredQueryParams.append(key, value)
    }
  })

  const canonicalUrl = `${consts.baseUrl}${parsedURL.pathname}${filteredQueryParams.toString() ? '?' + filteredQueryParams.toString() : ''}`

  return canonicalUrl
}
