import consts from '@/consts'

export default (): number | null => {
  const PAGE_PARAM = 'page'
  const requestURL = useRequestURL()

  const queryParams = new URLSearchParams(requestURL.search)

  const isPageParamAllowed = consts.allowedCanonicalQueryParams.some(
    (paramObj) => paramObj.param === PAGE_PARAM && paramObj.path.includes(requestURL.pathname),
  )

  if (isPageParamAllowed) {
    const pageParam = queryParams.get(PAGE_PARAM)

    if (pageParam !== null && !isNaN(Number(pageParam))) {
      return Number(pageParam)
    }
  }

  return null
}
